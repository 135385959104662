import React, { createContext, useState, useEffect, useContext } from 'react';

export const ThemeContext = createContext();

export const themes = {
  light: {
    background: '#ffffff',
    surfaceBackground: '#ffffff',
    inputBackground: '#ffffff',
    textPrimary: '#1a1a1a',
    textSecondary: '#666666',
    border: '#e2e8f0',
    accent: '#007AFF'
  },
  dark: {
    background: '#1a1a1a',
    surfaceBackground: '#2d2d2d',
    inputBackground: '#1a1a1a',
    textPrimary: '#ffffff',
    textSecondary: '#ffffff',
    border: '#404040',
    accent: '#0A84FF'
  }
};

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
      document.documentElement.setAttribute('data-theme', e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);
    handleChange(mediaQuery);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const theme = isDarkMode ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
