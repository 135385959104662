import React, { useState, useEffect, useContext } from 'react';
import { PollyClient, SynthesizeSpeechCommand } from '@aws-sdk/client-polly';
import { useTheme } from '../context/ThemeContext';
import './TextToSpeech.css';

const NEURAL_VOICES = {
  "Arabic (Gulf)": {
    code: "ar-AE",
    voices: [
      { id: "Hala", gender: "Female" },
      { id: "Zayd", gender: "Male" }
    ]
  },
  "Dutch (Belgian)": {
    code: "nl-BE",
    voices: [{ id: "Lisa", gender: "Female" }]
  },
  "Catalan": {
    code: "ca-ES",
    voices: [{ id: "Arlet", gender: "Female" }]
  },
  "Chinese (Cantonese)": {
    code: "yue-CN",
    voices: [{ id: "Hiujin", gender: "Female" }]
  },
  "Chinese (Mandarin)": {
    code: "cmn-CN",
    voices: [{ id: "Zhiyu", gender: "Female" }]
  },
  "Danish": {
    code: "da-DK",
    voices: [{ id: "Sofie", gender: "Female" }]
  },
  "Dutch": {
    code: "nl-NL",
    voices: [{ id: "Laura", gender: "Female" }]
  },
  "English (Australian)": {
    code: "en-AU",
    voices: [{ id: "Olivia", gender: "Female" }]
  },
  "English (British)": {
    code: "en-GB",
    voices: [
      { id: "Amy", gender: "Female" },
      { id: "Emma", gender: "Female" },
      { id: "Brian", gender: "Male" },
      { id: "Arthur", gender: "Male" }
    ]
  },
  "English (Indian)": {
    code: "en-IN",
    voices: [{ id: "Kajal", gender: "Female" }]
  },
  "English (Irish)": {
    code: "en-IE",
    voices: [{ id: "Niamh", gender: "Female" }]
  },
  "English (New Zealand)": {
    code: "en-NZ",
    voices: [{ id: "Aria", gender: "Female" }]
  },
  "English (South African)": {
    code: "en-ZA",
    voices: [{ id: "Ayanda", gender: "Female" }]
  },
  "English (US)": {
    code: "en-US",
    voices: [
      { id: "Danielle", gender: "Female" },
      { id: "Gregory", gender: "Male" },
      { id: "Ivy", gender: "Female" },
      { id: "Joanna", gender: "Female" },
      { id: "Kendra", gender: "Female" },
      { id: "Kimberly", gender: "Female" },
      { id: "Salli", gender: "Female" },
      { id: "Joey", gender: "Male" },
      { id: "Justin", gender: "Male" },
      { id: "Kevin", gender: "Male" },
      { id: "Matthew", gender: "Male" },
      { id: "Ruth", gender: "Female" },
      { id: "Stephen", gender: "Male" }
    ]
  },
  "Finnish": {
    code: "fi-FI",
    voices: [{ id: "Suvi", gender: "Female" }]
  },
  "French": {
    code: "fr-FR",
    voices: [
      { id: "Léa", gender: "Female" },
      { id: "Rémi", gender: "Male" }
    ]
  },
  "French (Belgian)": {
    code: "fr-BE",
    voices: [{ id: "Isabelle", gender: "Female" }]
  },
  "French (Canadian)": {
    code: "fr-CA",
    voices: [
      { id: "Gabrielle", gender: "Female" },
      { id: "Liam", gender: "Male" }
    ]
  },
  "German": {
    code: "de-DE",
    voices: [
      { id: "Vicki", gender: "Female" },
      { id: "Daniel", gender: "Male" }
    ]
  },
  "German (Austrian)": {
    code: "de-AT",
    voices: [{ id: "Hannah", gender: "Female" }]
  },
  "German (Swiss)": {
    code: "de-CH",
    voices: [{ id: "Sabrina", gender: "Female" }]
  },
  "Hindi": {
    code: "hi-IN",
    voices: [{ id: "Kajal", gender: "Female" }]
  },
  "Italian": {
    code: "it-IT",
    voices: [
      { id: "Bianca", gender: "Female" },
      { id: "Adriano", gender: "Male" }
    ]
  },
  "Japanese": {
    code: "ja-JP",
    voices: [
      { id: "Takumi", gender: "Male" },
      { id: "Kazuha", gender: "Female" },
      { id: "Tomoko", gender: "Female" }
    ]
  },
  "Korean": {
    code: "ko-KR",
    voices: [{ id: "Seoyeon", gender: "Female" }]
  },
  "Norwegian": {
    code: "nb-NO",
    voices: [{ id: "Ida", gender: "Female" }]
  },
  "Polish": {
    code: "pl-PL",
    voices: [{ id: "Ola", gender: "Female" }]
  },
  "Portuguese (Brazilian)": {
    code: "pt-BR",
    voices: [
      { id: "Camila", gender: "Female" },
      { id: "Vitória", gender: "Female" },
      { id: "Thiago", gender: "Male" }
    ]
  },
  "Portuguese (European)": {
    code: "pt-PT",
    voices: [{ id: "Inês", gender: "Female" }]
  },
  "Spanish (European)": {
    code: "es-ES",
    voices: [
      { id: "Lucia", gender: "Female" },
      { id: "Sergio", gender: "Male" }
    ]
  },
  "Spanish (Mexican)": {
    code: "es-MX",
    voices: [
      { id: "Mia", gender: "Female" },
      { id: "Andrés", gender: "Male" }
    ]
  },
  "Spanish (US)": {
    code: "es-US",
    voices: [
      { id: "Lupe", gender: "Female" },
      { id: "Pedro", gender: "Male" }
    ]
  },
  "Swedish": {
    code: "sv-SE",
    voices: [{ id: "Elin", gender: "Female" }]
  },
  "Turkish": {
    code: "tr-TR",
    voices: [{ id: "Burcu", gender: "Female" }]
  }
};

const TextToSpeech = () => {
  const { isDarkMode } = useTheme();

  // State variables
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [inputText, setInputText] = useState('');
  const [voices, setVoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [error, setError] = useState(null);

  // Update voices when language changes
  useEffect(() => {
    if (selectedLanguage) {
      setVoices(NEURAL_VOICES[selectedLanguage].voices || []);
      setSelectedVoice('');
    }
  }, [selectedLanguage]);

  // Helper function to get language code
  const getLanguageCode = (language) => {
    const languageCodes = {
      'English (US)': 'en-US',
      'English (UK)': 'en-GB',
      'French': 'fr-FR',
      'German': 'de-DE',
    };
    return languageCodes[language];
  };

  // Handle text input change
  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  // Generate speech
  const generateSpeech = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const polly = new PollyClient({
        region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Engine: 'neural',
        LanguageCode: getLanguageCode(selectedLanguage),
        Text: inputText,
        OutputFormat: 'mp3',
        VoiceId: selectedVoice,
      };

      const command = new SynthesizeSpeechCommand(params);
      const response = await polly.send(command);
      
      if (response.AudioStream) {
        // Convert AudioStream to ArrayBuffer
        const audioArray = await response.AudioStream.transformToByteArray();
        const audioBlob = new Blob([audioArray], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        
        setAudioData({
          url: audioUrl,
          blob: audioBlob
        });
      }
    } catch (error) {
      console.error('Error generating speech:', error);
      setError('Error generating speech. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Add cleanup for audio URLs when component unmounts
  useEffect(() => {
    return () => {
      if (audioData?.url) {
        URL.revokeObjectURL(audioData.url);
      }
    };
  }, [audioData]);

  // Add this function to handle downloads
  const handleDownload = () => {
    if (audioData?.url) {
      // Create a temporary anchor element
      const a = document.createElement('a');
      a.href = audioData.url;
      a.download = 'text-to-speech.mp3'; // Set the download filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div className={`tts-container ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="controls">
        <select 
          value={selectedLanguage} 
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="">Select Language</option>
          {Object.keys(NEURAL_VOICES).map((language) => (
            <option key={language} value={language}>
              {language}
            </option>
          ))}
        </select>

        <select 
          value={selectedVoice} 
          onChange={(e) => setSelectedVoice(e.target.value)}
          disabled={!selectedLanguage}
        >
          <option value="">Select Voice</option>
          {selectedLanguage && NEURAL_VOICES[selectedLanguage].voices.map((voice) => (
            <option key={voice.id} value={voice.id}>
              {voice.id} ({voice.gender})
            </option>
          ))}
        </select>
      </div>

      <textarea
        value={inputText}
        onChange={handleTextChange}
        placeholder="Enter text to convert to speech..."
        maxLength={3000}
      />
      
      <div className="char-count">
        {inputText.length} / 3000 characters
      </div>

      <button 
        onClick={generateSpeech}
        disabled={!selectedLanguage || !selectedVoice || !inputText || isLoading}
        className="generate-btn"
      >
        {isLoading ? 'Generating...' : 'Generate Speech'}
      </button>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {audioData && (
        <div className="audio-controls">
          <audio controls src={audioData.url} />
          <button 
            className="download-btn"
            onClick={handleDownload}
            aria-label="Download audio"
          >
            <svg 
              className="download-icon" 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="7 10 12 15 17 10" />
              <line x1="12" y1="15" x2="12" y2="3" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech; 