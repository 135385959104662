import React from 'react';
import TextToSpeech from './components/TextToSpeech';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import logoLight from './assets/logo.svg';
import logoDark from './assets/logo-white.svg';
import './App.css';

function AppContent() {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="app">
      <header className="app-header">
        <img 
          src={isDarkMode ? logoDark : logoLight} 
          alt="Text to Speech Logo" 
          className="logo"
        />
        <h1 className="welcome-text">Transforming text into lifelike voices—powered by AI and inspired by sound for free.</h1>
      </header>
      <main>
        <TextToSpeech />
      </main>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
